import PCLogo from '../../../public/images/icon_pc.png';
import PSLogo from '../../../public/images/icon_ps.png';
import XBLogo from '../../../public/images/icon_xb.png';

export default function PlatformBadge({ platform }) {
    const platformIconSrc = () => {
        switch(platform.short_name) {
            case 'PC': 
                return PCLogo;
            case 'PS':
                return PSLogo;
            case 'XB':
                return XBLogo;
        }
    }

    return (
        <>
            {platform &&
            <div className={`absolute z-10 px-2 py-3 transform -skew-x-12 bg-primary -top-2 right-2`}>
                <div className="transform skew-x-12">
                    <img src={platformIconSrc()} />
                </div>
            </div>
            }
        </>
    );
}
